<template>
  <!-- <AppLayout> -->
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  <!-- </AppLayout> -->
</template>

<script>

export default {
};
</script>

<style>
</style>
